<template>
  <div class="public-container white darken-3">
    <search-accesses
      v-if="activeStep === 1"
      @set-address="setAddress"
      @set-accesses="setAccesses"
      @go-to-next="goNextStep"
    />

    <choose-access
      v-if="activeStep === 2"
      :address="address.value"
      :accesses="availableAccesses"
      :stokab-points="stokabPoints"
      @set-products-services="setProductsServices"
      @go-to-next="goNextStep"
    />

    <choose-network
      v-if="activeStep === 3"
      :address="address.value"
      :access="selectedAccess"
      :stokab-product="selectedStokabProduct"
      :products="products"
      :services="services"
      @set-product="(data) => selectedProduct = data"
      @set-service="(data) => selectedService = data"
      @set-delivery-date="(date) => expectDeliveryDate = date"
      @go-to-next="goNextStep"
      @go-to-back="goBackStep"
      @init-data="initData"
    />

    <order-info
      v-if="activeStep === 4"
      :product="selectedProduct"
      :service="selectedService"
      :access="selectedAccess"
      :expected-delivery="expectDeliveryDate"
      :address="address"
      :stokab-product="selectedStokabProduct"
      @set-order-data="(data) => orderData = data"
      @go-to-back="goBackStep"
      @go-to-next="goNextStep"
    />

    <order-confirm
      v-if="activeStep === 5"
      :order-data="orderData"
      @init-data="initData"
    />
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  import SearchAccesses from './SearchAccesses'
  import ChooseAccess from './ChooseAccess'
  import ChooseNetwork from './ChooseNetwork'
  import OrderInfo from './OrderInfo'
  import OrderConfirm from './OrderConfirm'

  import { showErrorDialog } from '@/utils/swalDialog'
  import util from '@/utils'

  export default {
    metaInfo: {
      title: 'Search Network',
    },

    components: {
      SearchAccesses,
      ChooseAccess,
      ChooseNetwork,
      OrderInfo,
      OrderConfirm,
    },

    data () {
      return {
        activeStep: 1,
        address: null,
        accesses: null,
        selectedAccess: null,
        selectedStokabPoint: null,
        selectedStokabProduct: null,
        selectedProduct: null,
        selectedService: null,
        expectDeliveryDate: null,
        services: [],
        products: [],
        orderData: null,
      }
    },

    computed: {
      stokabPoints() {
        return this.accesses?.stokab?.availablePoints || []
      },

      availableSollentunaAccesses() {
        if (!this.accesses || !this.accesses.sollentuna || !this.accesses.sollentuna.rows) {
          return []
        }

        return this.accesses.sollentuna.rows.map(item => ({
          ...item,
          network: 'sollentuna',
          networkName: 'Sollentuna',
        }))
      },

      availableZitiusAccesses() {
        if (!this.accesses || !this.accesses.zitius || !this.accesses.zitius.rows) {
          return []
        }

        return this.accesses.zitius.rows.map(item => ({
          ...item,
          network: 'zitius',
          networkName: 'Zitius',
        }))
      },

      availableOpeninfraAccesses() {
        if (!this.accesses || !this.accesses.openinfra || !this.accesses.openinfra.rows) {
          return []
        }

        return this.accesses.openinfra.rows.map(item => ({
          ...item,
          network: 'openinfra',
          networkName: 'Openinfra',
        }))
      },

      availableOpenuniverseAccesses() {
        if (!this.accesses || !this.accesses.openuniverse || !this.accesses.openuniverse.rows) {
          return []
        }

        return this.accesses.openuniverse.rows.map(item => ({
          ...item,
          network: 'openuniverse',
          networkName: 'Openuniverse',
        }))
      },

      availableIpOnlyAccesses() {
        if (!this.accesses || !this.accesses.ipOnly || !this.accesses.ipOnly.rows) {
          return []
        }

        return this.accesses.ipOnly.rows.map(item => ({
          ...item,
          network: 'ipOnly',
          networkName: 'IP-Only',
        }))
      },

      availableAccesses() {
        const allAccesses = [
          ...this.availableSollentunaAccesses,
          ...this.availableOpeninfraAccesses,
          ...this.availableOpenuniverseAccesses,
          ...this.availableIpOnlyAccesses,
          ...this.availableZitiusAccesses,
        ]

        return allAccesses.map((item) => {
          return {
            ...item,
            label: `${item.mduApartmentNumber || item.mduDistinguisher || 'N/A'} - ${item.accessId}`
          }
        })
      },

      totalAccessCount() {
        return this.availableAccesses.length + this.stokabPoints.length
      },
    },

    watch: {
      activeStep() {
        this.setLoading(false)
      },
    },

    mounted() {
      const grandidsession = this.$route.query.grandidsession || ''

      if (!grandidsession) {
        return
      }

      this.selectedProduct = JSON.parse(localStorage.getItem('product'))
      this.selectedService = JSON.parse(localStorage.getItem('service'))
      this.selectedAccess = JSON.parse(localStorage.getItem('access'))
      this.selectedStokabProduct = JSON.parse(localStorage.getItem('stokabProduct'))
      this.address = JSON.parse(localStorage.getItem('address'))
      this.expectDeliveryDate = localStorage.getItem('expectedDelivery')
      const orderData = localStorage.getItem('orderData')

      localStorage.removeItem('product')
      localStorage.removeItem('service')
      localStorage.removeItem('access')
      localStorage.removeItem('address')
      localStorage.removeItem('stokabProduct')
      localStorage.removeItem('expectedDelivery')

      if (!orderData) {
        return
      }

      this.activeStep = 4
    },

    methods: {
      ...mapActions({
        setLoading: 'setLoading',
        setLoadingText: 'setLoadingText',
        getAccessDetail: 'getAccessDetail',
        getStokabNetworks: 'getStokabNetworks',
      }),

      setAddress (address) {
        this.address = address
      },

      setAccesses (accesses) {
        this.accesses = accesses
      },

      setProductsServices (data) {
        this.selectedAccess = data.access
        this.services = data.services
        this.products = data.products
        this.selectedStokabProduct = data.stokabProduct
      },

      async loadAccessDetail () {
        this.setLoadingText('Hittar tjänster...')
        this.setLoading(true)

        const selectedAccess = this.availableAccesses[0]
        try {
          const payload = {
            type: selectedAccess.network,
            accessId: selectedAccess.accessId,
            networkCategory: 'residential',
          }

          const response = await this.getAccessDetail(payload)

          this.setLoading(false)

          const services = response.services.filter(item => item.connection !== 'NO' || item.available !== 'NO')

          if (!response || !response.products || response.products.length === 0) {
            showErrorDialog('Här finns vi tyvärr inte just nu.')
            return
          }

          this.setProductsServices({
            services,
            products: response.products || [],
            access: selectedAccess,
          })

          this.activeStep +=2
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog('Det blev fel!', errorText)
          this.setLoading(false)
        }
      },

      async loadStokabDetail() {
        this.setLoadingText('Hittar tjänster...')
        this.setLoading(true)

        try {
          const pointItem = this.stokabPoints[0]

          const payload = {
            pointId: pointItem.pointId,
            type: pointItem.type,
            networkCategory: 'residential',
          }

          const response = await this.getStokabNetworks(payload)

          this.setProductsServices({
            products: response.productList || [],
            stokabProduct: response.product,
          })

          this.activeStep +=2
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog('Det blev fel!', errorText)
          this.setLoading(false)
        }
      },

      async goNextStep () {
        if (this.activeStep === 1) {
          if (this.availableAccesses.length === 0 && this.stokabPoints.length === 0) {
            showErrorDialog('Här finns vi tyvärr inte just nu.')
            this.setLoading(false)
            return
          }

          if (this.totalAccessCount === 1) {
            if (this.availableAccesses.length === 1) {
              await this.loadAccessDetail()
              return
            } else {
              await this.loadStokabDetail()
              return
            }
          }
        }

        this.activeStep++
      },

      goBackStep () {
        this.activeStep--
      },

      initData () {
        this.activeStep = 1
        this.address = null
        this.accesses = null
        this.selectedAccess = null
        this.selectedProduct = null
        this.expectDeliveryDate = null
        this.services = []
        this.products = []
        this.orderData = null
      },
    },
  }

</script>

<style lang="scss">
.public-container {
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  padding: 10px 1rem;
  justify-content: center;
}

.public-new-search {
  width: 100%;

  a {
    color: #5B46F6;
    &:hover {
      color: #F00;
    }
  }

  strong {
    font-weight: bold;
  }

  .main-panel {
    background: #e4e3ef;
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 15px;
    padding: 20px 40px;
  }

  .search-area {
    flex: 1;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
  }

  .status-area {
    display: flex;
    justify-self: start;
    width: 100%;

    .status-item {
      display: flex;
      align-items: center;
    }

    .status-bullet {
      width: 20px;
      height: 20px;
      display: inline-block;
      border-radius: 100%;
      margin-right: 10px;

      &.sneaky {
        background: #5d8ceb;
      }

      &.binding {
        background: #8f8af0;
      }

      &.period {
        background: #c692e5;
      }
    }
  }

  button.v-btn {
    border-radius: 12px;
    padding-top: 18px !important;
    padding-bottom: 18px !important;
    height: unset !important;
    box-shadow: none;
    text-transform: capitalize;

    &.btn-back {
      background: #DEDBF2;
      color: #5B46F6;
    }

    &.btn-next {
      background: #5B46F6;
      color: #FFF;
    }
  }

  @media only screen and (min-width: 1240px) {
    max-width: 1200px;
  }
}
</style>
