<template>
  <v-row class="public-new-search search-address">
    <v-col
      cols="12"
      sm="8"
    >
      <div class="main-panel">
        <div class="search-area">
          <p class="text-h4 font-weight-bold text-center">
            Hitta rätt pris och hastighet för din anslutning genom att fylla i din adress
          </p>
          <v-autocomplete
            v-model="streetName"
            :items="streetCityList"
            :loading="isLoading"
            :search-input.sync="searchStreet"
            class="mb-4"
            item-text="value"
            item-value="value"
            :append-icon="null"
            prepend-icon="mdi-map-marker"
            return-object
            hide-details
            hide-no-data
            clearable
            :filter="() => true"
            @blur="() => resetAutoSuggestionList()"
          >
            <template v-slot:label>
              <span>
                Gata och nummer
              </span>
            </template>
            <template v-slot:selection="data">
              <span>
                {{ data.item.value }}
              </span>
            </template>
            <template v-slot:item="data">
              <v-icon class="pr-3">
                mdi-map-marker
              </v-icon> {{ data.item.value }}
            </template>
          </v-autocomplete>
        </div>
        <div class="status-area">
          <div class="status-item mr-10">
            <span class="status-bullet sneaky">&nbsp;</span>
            <span class="text-subtitle-1">Inga smyghöjningar</span>
          </div>

          <div class="status-item mr-10">
            <span class="status-bullet binding">&nbsp;</span>
            <span class="text-subtitle-1">Ingen bindningstid</span>
          </div>

          <div class="status-item">
            <span class="status-bullet period">&nbsp;</span>
            <span class="text-subtitle-1">1 månads uppsägningstid</span>
          </div>
        </div>
      </div>
    </v-col>

    <v-col
      cols="12"
      sm="4"
    >
      <img src="/static/img/search/search_side.png" style="height: 100%;">
    </v-col>
  </v-row>
</template>

<script>
  import * as _ from 'lodash'
  import { mapActions, mapGetters } from 'vuex'

  import util from '@/utils'
  import { showErrorDialog } from '@/utils/swalDialog'

  export default {
    data () {
      return {
        isLoading: false,
        streetName: '',
        streetCityList: [],
        searchStreet: null,
        address: null,
        accesses: null,
      }
    },

    watch: {
      searchStreet: {
        handler: _.debounce(function (value) {
          if (this.isLoading || !value || value.length === 0) {
            this.streetCityList = []
            return
          }

          this.getSuggestionListWithStreetAndCity(value)
        }, 500),
      },

      streetName (value) {
        if (!value) {
          this.streetCityList = []
          this.address = null
          return
        }

        this.chooseStreetAndCity(value)
      },
    },

    methods: {
      ...mapActions({
        getSuggestionAddress: 'getSuggestionAddress',
        getAccessAndProducts: 'getAccessAndProducts',
        setLoading: 'setLoading',
        setLoadingText: 'setLoadingText',
      }),

      async getSuggestionListWithStreetAndCity(queryString) {
        const payload = {
          query: queryString,
          columns: 'street,locality, postalcode,street_number,letter',
        }

        this.address = null
        this.isLoading = true
        try {
          const response = await this.getSuggestionAddress(payload)
          this.streetCityList = response.rows.map(item => ({
            value: util.getFullAddress(item),
            streetLetter: item.letter,
            street: item.street,
            streetNumber: item.street_number,
            locality: item.locality,
            postalCode: item.postalcode,
          }))
        } catch (error) {
          this.streetCityList = []
        }
        this.isLoading = false
      },

      chooseStreetAndCity(result) {
        this.address = result

        this.fetchAccessesByAddress()
      },

      async fetchAccessesByAddress() {
        this.setLoading(true)
        this.setLoadingText('Laddar Tiillgängliga Tjänster...')
        this.accesses = null

        try {
          const payload = {
            street: this.address.street,
            streetNumber: this.address.streetNumber,
            streetLetter: this.address.streetLetter,
            locality: this.address.locality,
            postalCode: this.address.postalCode,
            networkCategory: this.networkCategory,
          }

          this.accesses = await this.getAccessAndProducts(payload)

          this.$emit('set-address', this.address)
          this.$emit('set-accesses', this.accesses)
          this.$emit('go-to-next')
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog('Det blev fel!', errorText)
          this.setLoading(false)
        }
      },

      resetAutoSuggestionList() {
        if (this.streetName) {
          return
        }

        this.searchStreet = ''
        this.streetCityList = []
      },
    },
  }
</script>

<style lang="scss">
.public-new-search.search-address {
  .search-area {
    .v-input.v-autocomplete {
      flex: 0;
      width: 70%;
      min-width: 350px;
      margin-top: 30px;
    }
  }
}


</style>