<template>
  <v-row class="public-new-search">
    <v-col
      cols="12"
      sm="8"
    >
      <div class="main-panel">
        <div class="search-area">
          <p class="text-h4 font-weight-bold text-center">
            Hitta rätt pris och hastighet för din anslutning genom att fylla i din adress
          </p>
          <p class="text-h6 purple--text mt-4 mb-6" >
            {{ address }}
          </p>
          <v-select
            v-model="accessId"
            :items="availableAccesses"
            label="Välj lägenhetsnummer"
            item-text="label"
            item-value="accessId"
            hide-details
            hint="What are the target regions"
            clearable
          />
          <span class="text-caption text-left w-full red--text">
            Data Format: Apt Num - Access Id (Network Type)
          </span>
        </div>

        <div class="status-area">
          <div class="status-item mr-10">
            <span class="status-bullet sneaky">&nbsp;</span>
            <span class="text-subtitle-1">Inga smyghöjningar</span>
          </div>

          <div class="status-item mr-10">
            <span class="status-bullet binding">&nbsp;</span>
            <span class="text-subtitle-1">0 <strong>män</strong> bindningstid</span>
          </div>

          <div class="status-item">
            <span class="status-bullet period">&nbsp;</span>
            <span class="text-subtitle-1">1 <strong>män</strong> uppsägningstid</span>
          </div>
        </div>
      </div>
    </v-col>

    <v-col
      cols="12"
      sm="4"
    >
      <img src="/static/img/search/search_side.png">
    </v-col>
  </v-row>
</template>

<script>
  import { mapActions } from 'vuex'

  import { showErrorDialog } from '@/utils/swalDialog'
  import util from '@/utils'

  export default {
    props: {
      address: {
        type: String,
      },

      accesses: {
        type: Array,
        default: () => [],
      },

      stokabPoints: {
        type: Array,
        default: () => [],
      },
    },

    computed: {
      availableAccesses() {
        const data = this.accesses || []

        for (const item of this.stokabPoints) {
          data.push({
            type: 'Stokab',
            accessId: item.pointId,
            label: `${item.pointId} - ${util.getStokabTypeName(item.type)} (Stokab)`
          })
        }

        return data
      }
    },

    data () {
      return {
        accessId: '',
      }
    },

    watch: {
      accessId (value) {
        if (!value) {
          this.$emit('set-access', null)
          return
        }

        this.chooseAccess()
      },
    },

    methods: {
      ...mapActions({
        setLoading: 'setLoading',
        setLoadingText: 'setLoadingText',
        getAccessDetail: 'getAccessDetail',
        getStokabNetworks: 'getStokabNetworks',
      }),

      chooseAccess() {
        const selectedAccess = this.availableAccesses.find(item => item.accessId === this.accessId)

        if (!selectedAccess) {
          return
        }

        if (selectedAccess.type === 'Stokab') {
          this.loadStokabDetail()
        } else {
          this.loadAccessDetail()
        }
      },

      async loadAccessDetail() {
        this.setLoadingText('Hittar tjänster...')
        this.setLoading(true)

        const selectedAccess = this.accesses.find(item => item.accessId === this.accessId)
        try {
          const payload = {
            type: selectedAccess.network,
            accessId: selectedAccess.accessId,
            networkCategory: 'residential',
          }

          const response = await this.getAccessDetail(payload)

          this.setLoading(false)

          const services = response.services.filter(item => item.connection !== 'NO' || item.available !== 'NO')

          if (!response || !response.products || response.products.length === 0) {
            showErrorDialog('Här finns vi tyvärr inte just nu.')
            return
          }

          this.$emit('set-products-services', {
            services,
            products: response.products || [],
            access: selectedAccess,
          })

          this.$emit('go-to-next')
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog('Det blev fel!', errorText)
          this.setLoading(false)
        }
      },

      async loadStokabDetail() {
        this.setLoadingText('Hittar tjänster...')
        this.setLoading(true)

        const pointItem = this.stokabPoints.find(item => item.pointId === this.accessId)

        try {
          const payload = {
            pointId: pointItem.pointId,
            type: pointItem.type,
            networkCategory: 'residential',
          }

          const response = await this.getStokabNetworks(payload)

          this.$emit('set-products-services', {
            products: response.productList || [],
            stokabProduct: response.product,
          })

          this.$emit('go-to-next')
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog('Det blev fel!', errorText)
          this.setLoading(false)
        }
      },
    },
  }
</script>

<style lang="scss">
.public-new-search {
  .search-area {
    .v-input.v-select {
      flex: 0;
      width: 100%;
    }
  }
}
</style>
